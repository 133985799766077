import "survey-core/modern.min.css";
import { StylesManager } from "survey-core";
import { useEffect, useState } from "react";
import http from "../../http-common";
import { useParams } from "react-router-dom";

// project import
import { ApiResponse } from "../../models/RCContent";
import ErrorMessage from "../maintenance/error";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Grid, Stack, styled, useMediaQuery, Link, Typography } from "@mui/material";
import { getClientIpAddress } from "../../eventHandlers/getClientIpAddress";
import HLSPlayer from "./HLSPlayer";
import React from "react";
import { ContentGuidType } from "../../models/ContentGuidType";
import ContentRating from "../content/ContentRating";
import Markdown from "react-markdown";
import { getContent } from "../../eventHandlers/getContent";

StylesManager.applyTheme("modern");

interface VideoDefinition {
  src: string;
  type: string;
  title: string;
  desc: string;
  aspectRatio: string;
  posterFileName: string;
  disclaimer: string;
  copyRight: string;
  subtitleFileName: string;
  subtitleLanguage: string;
  subtitleLabel: string;
}

async function getStaticContent(PatientId: string, ContentId: string) {
  const ip = await getClientIpAddress();
  var content = await http.get<ApiResponse>(
    `/api/retrieveStaticContent/${PatientId}/${ContentId}/${ip}`
  );
  return content;
}

export async function saveContentViewed(contentKey: string) {
  return http.post(
    "/api/saveData/" + contentKey,
    JSON.stringify({
      contentKey: contentKey,
      data: { comment: "Patient viewed video." },
    })
  );
}

const SectionHeaderItem = styled(Box)(({ theme }) => ({
  backgroundColor: '#443768',
  ...(useMediaQuery(theme.breakpoints.up('sm')) ? theme.typography.h4 : theme.typography.h6),
  padding: theme.spacing(3),
  textAlign: 'left',
  color: 'white'
}));

const TitleItem = styled(Box)(({ theme }) => ({
  backgroundColor: '#443768',
  ...(useMediaQuery(theme.breakpoints.up('sm')) ? theme.typography.subtitle1 : theme.typography.subtitle2),
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white'
}));

const DescriptionItem = styled(Typography)(({theme}) => ({
  ...theme.typography.caption,
  backgroundColor: '#443768',
  color: 'white',
  paddingLeft: theme.spacing(1),
  textAlign: 'center'
}));

const DisclaimerItem = styled(Box)(({ theme }) => ({
  ...(useMediaQuery(theme.breakpoints.up('sm')) ? theme.typography.body2 : theme.typography.body2),
  padding: theme.spacing(1),
  textAlign: 'left'
}));

const RatingItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0,1)
}));

const CopyrightItem = styled(Box)(({ theme }) => ({
  ...(useMediaQuery(theme.breakpoints.up('sm')) ? theme.typography.body1 : theme.typography.body2),
  padding: theme.spacing(1),
  textAlign: 'left',
  color: 'grey'
}));

const Video = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between("xs", "sm")]: {
    width: "100%"
  },
  [theme.breakpoints.between("sm", "md")]: {
    width: "60%"
  },
  [theme.breakpoints.between("md", "lg")]: {
    width: "50%"
  },
  [theme.breakpoints.up("lg")]: {
    width: "40%"
  }
}));

interface RcVideoProps {
  contentId?: string | undefined
}

const RcVideo = (props: RcVideoProps) => {
  let { contentId, memoraPatientId, contentKey } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [rcContent, setRcContent] = useState({
    status: "",
    errorInformation: "",
    contentId: "",
    contentKey: "",
    contentType: "",
    contentDefinintion: "",
  });
  const [video, setVideo] = useState<VideoDefinition>();

  contentId = props.contentId ? props.contentId : contentId;

  useEffect(() => {
    async function fetchContent(patientId: string, contentId: string) {
      await getStaticContent(patientId, contentId).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "SUCCESS") {
            setRcContent(response.data);

            setVideo(response.data.contentDefinintion as any as VideoDefinition);

            saveContentViewed(response.data.contentKey);
          } else {
            switch (response.data.status) {
              case "KEY-INVALID": {
                setErrorMessage(
                  "There seems to be a problem with this link..."
                );
                setErrorStatus("Invalid link!");
                break;
              }
              case "DATA-EXISTS": {
                setErrorMessage("This survey has already been completed.");
                setErrorStatus("Completed Survey!");
                break;
              }
              default: {
                setErrorMessage("This link is expired and no longer usable...");
                setErrorStatus("Expired link!");
              }
            }
          }
        } else {
          setErrorMessage("Error getting content!");
        }
        setLoading(false);
      });
    }

    async function fetchContentViaContentRequestId() {
      await getContent(contentKey).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "SUCCESS") {
            setRcContent(response.data);

            setVideo(response.data.contentDefinintion as any as VideoDefinition);

            saveContentViewed(response.data.contentKey);
          } else {
            switch (response.data.status) {
              case "KEY-INVALID": {
                setErrorMessage(
                  "There seems to be a problem with this link..."
                );
                setErrorStatus("Invalid link!");
                break;
              }
              default: {
                setErrorMessage("This link is expired and no longer usable...");
                setErrorStatus("Expired link!");
              }
            }
          }
        } else {
          setErrorMessage("Error getting content!");
        }
      }).catch(() => {
        setErrorMessage("Error getting content!");
      });
      setLoading(false);
    }

    if (rcContent.contentId === "" && memoraPatientId && contentId) {
      fetchContent(memoraPatientId, contentId);
    } else if (contentKey) {
      fetchContentViaContentRequestId();
    }
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {errorMessage ? (
            <ErrorMessage
              errorMessage={errorMessage}
              errorStatus={errorStatus}
            />
          ) : (
            <Box sx={{ paddingLeft: {lg:'15%'}, paddingRight: {lg:'15%'} }} paddingBottom={'1%'}>
              <Stack sx={{ border: {lg: 1} }}>
                {video?.title !== '' ?
                  <TitleItem>{video?.title}</TitleItem> :
                  undefined
                }
                {video?.desc !== '' ?
                  <DescriptionItem>{video?.desc}</DescriptionItem> :
                  undefined
                }
                  <Video>
                    <HLSPlayer
                      title={video?.title ?? ''}
                      description={video?.desc ?? ''}
                      url={video?.src ?? ''}
                      contentId={contentId}
                      patientId={memoraPatientId}
                      posterFileName={video?.posterFileName}
                      subtitleConfig={ 
                        { fileName: video!.subtitleFileName, language: video!.subtitleLanguage, label: video!.subtitleLabel }
                      }
                    />
                  </Video>
                  
                <Grid container>
                  <Grid item xs={9} md={7}>
                    <SectionHeaderItem><Markdown components={{p: React.Fragment}}>_How would you rate this content?_</Markdown></SectionHeaderItem>
                  </Grid>
                </Grid>
                <RatingItem>
                  <ContentRating memoraPatientId={memoraPatientId!} contentGuid={contentId!} contentGuidType={ContentGuidType.contentConfigurationId}/>
                </RatingItem>
                <DisclaimerItem><Markdown>{video?.disclaimer ?? ''}</Markdown></DisclaimerItem>
                <CopyrightItem><Markdown>{video?.copyRight ?? ''}</Markdown> <Link href="/content/terms" target="_blank" rel="noopener noreferrer">Website Terms</Link></CopyrightItem>
              </Stack>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default RcVideo;
