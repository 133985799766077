import { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Grid, TextField, Box, CircularProgress, Button, Alert } from "@mui/material";
import http from "../../../http-common";
import { EligiblePatientLookupInfo } from "../../../types/patient";
import { executeRecaptcha } from "../../../eventHandlers/executeRepatcha";
import { getClientIpAddress } from "../../../eventHandlers/getClientIpAddress";

export interface IEnrollmentEligibiltyLookupFormProps {
  provider: string | undefined;
  products: string[] | undefined;
  providerSpecificErrorMessage: string;
  recaptchaSiteKey: string;
  buttonLoading: boolean;
  error: string;
  onSuccessfulSubmit(enrolledProducts: string[] | undefined, formValues: any, patientAlreadyEnrolled: boolean): void;
  onBackButtonClick(): void;
}

async function validateEnrollmentEligibility (
  eligiblePatientLookupInfo: EligiblePatientLookupInfo,
  token: string
) {
  const ip = await getClientIpAddress();
  return http.post(
    "/api/validateEnrollmentEligibility/",
    JSON.stringify({...eligiblePatientLookupInfo, recaptchaToken: token, ipAddress: ip})
  );
}

const initialFormValues = {
  enrollmentCode: "",
  firstName: "",
  lastName: "",
  contact: "",
  dob: null
}

export const EnrollmentEligibiltyLookupForm = ({
  provider,
  products,
  providerSpecificErrorMessage,
  recaptchaSiteKey,
  buttonLoading,
  error,
  onSuccessfulSubmit,
  onBackButtonClick
}: IEnrollmentEligibiltyLookupFormProps) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState(initialFormValues);
  //const [recaptchaSiteKey, setRecaptchaSiteKey] = useState('');
  const phoneRegEx = /^\d{10}$/;

  useEffect(() => {
    setLoading(buttonLoading);
  }, [buttonLoading])

  useEffect(() => {
    setErrorMessage(error);
  }, [error])
  
  return (
    <Formik
      initialValues={formData}
      validationSchema={Yup.object().shape({
        enrollmentCode: Yup.string().required("Enrollment Code is required.").nullable(),
        firstName: Yup.string()
          .required("First Name is required.")
          .nullable(),
        lastName: Yup.string()
          .required("Last Name is required.")
          .nullable(),
        contact: Yup.string()
          .required("Mobile Phone Number is required")
          .matches(phoneRegEx, "Invalid Phone Number"),
        dob: Yup.date()
          .required("Date Of Birth is required")
          .nullable(),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ) => {
        setLoading(true);
        setErrorMessage("");
        const token = await executeRecaptcha(recaptchaSiteKey, 'submitEligibiltyLookupForm');

        await validateEnrollmentEligibility({
          enrollmentCode: values.enrollmentCode,
          firstName: values.firstName,
          lastName: values.lastName,
          provider: provider ? provider : "",
          dob: values.dob!,
          contact: values.contact
        }, token)
          .then((response) => {
            setLoading(false);
            switch(response.data.status.toLowerCase()) {
              case("not-found"):
                setErrorMessage(`${response.data.errorInformation}. ${providerSpecificErrorMessage}`);
                break;
              case("patient-enrolled"):
                // memora is always something they should be enrolled in but won't be in the url, so we manually add it to the list here
                products === undefined ? products = ['memora'] : products.push('memora');
                if (products !== undefined) {
                  const productsInQueryParameterThatAreAlreadyEnrolled = products.filter((product) => {
                    return response.data.data.includes(product);
                  });
                  if (productsInQueryParameterThatAreAlreadyEnrolled.length === products.length) {  // they're already enrolled in all the products they're trying to enroll in
                    setErrorMessage(`${response.data.errorInformation}. ${providerSpecificErrorMessage}`);
                    break;
                  } else {
                    setFormData(values);
                    onSuccessfulSubmit(response.data.data, values, true);
                    break;
                  }
                }
                setErrorMessage(`${response.data.errorInformation}. ${providerSpecificErrorMessage}`);
                break;
              case("phone-inuse"):
                setErrors({contact: "Invalid Phone Number"});
                setErrorMessage(`${response.data.errorInformation}. ${providerSpecificErrorMessage}`);
                break;
              case("success"):
                setFormData(values);
                onSuccessfulSubmit(undefined, values, false);
                break;
              default:
                setErrorMessage(`We are sorry, something went wrong. ${providerSpecificErrorMessage}`);
                break;
            }
          })
          .catch((error) => {
            setLoading(false);
            setErrorMessage(`We are sorry, something went wrong. ${providerSpecificErrorMessage}`);
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          id="enrollment-eligibility-lookup-form"
          noValidate
          onSubmit={handleSubmit}
        >
          <Grid
            sx={{
              display: "grid",
              gridTemplateColumns:
                "repeat(auto-fill, minmax(250px, 1fr))",
            }}
            container
            spacing={4}
            paddingBottom={2}
          >
            <Grid item>
              <TextField
                placeholder="Enrollment Code"
                fullWidth
                required
                id="enrollmentCode"
                name="enrollmentCode"
                value={values.enrollmentCode}
                label={"Enrollment Code"}
                variant="standard"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.enrollmentCode && Boolean(errors.enrollmentCode)}
                helperText={touched.enrollmentCode && errors.enrollmentCode}
              />
            </Grid>
            <Grid item>
              <TextField
                placeholder="First Name"
                fullWidth
                required
                id={"first-name"}
                name={"firstName"}
                value={values.firstName}
                label={"First Name"}
                variant="standard"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
            </Grid>
            <Grid item>
              <TextField
                placeholder="Last Name"
                fullWidth
                required
                id={"last-name"}
                name={"lastName"}
                value={values.lastName}
                label={"Last Name"}
                variant="standard"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  disableFuture
                  views={["year", "month", "day"]}
                  value={values.dob}
                  onChange={(newValue: any) => {
                    setFieldValue("dob", newValue);
                  }}
                  label={"Date of Birth"}
                  slotProps={{
                    textField: {
                      name: "dob",
                      id: "date-of-birth",
                      onBlur: handleBlur,
                      required: true,
                      helperText:
                        touched.dob && errors.dob,
                      error:
                        touched.dob &&
                        Boolean(errors.dob),
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <TextField
                placeholder="##########"
                fullWidth
                required
                id={"contact"}
                name={"contact"}
                value={values.contact}
                label={"Mobile Phone Number"}
                variant="standard"
                onChange={handleChange}
                onBlur={handleBlur}
                type="tel"
                error={touched.contact && Boolean(errors.contact)}
                helperText={touched.contact && errors.contact}
              />
            </Grid>
          </Grid>
          <Box sx={{ mb: 2 }}>
            <div>
              {loading && (
                <CircularProgress
                  size={30}
                  sx={{
                    position: "absolute",
                    marginTop: "12px",
                    marginLeft: "35px",
                  }}
                />
              )}
              <Button
                form="enrollment-eligibility-lookup-form"
                variant="contained"
                sx={{ mt: 1, mr: 1 }}
                type="submit"
                disabled={loading}
              >
                Continue
              </Button>
              <Button onClick={onBackButtonClick} sx={{ mt: 1, mr: 1 }}>
                Back
              </Button>
            </div>
            {errorMessage && (
              <Alert severity="error" sx={{ mt: 1 }}>
                {errorMessage}
              </Alert>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
}