import "survey-core/modern.min.css";
import { StylesManager } from "survey-core";
import { useEffect, useRef } from "react";
import http from "../../http-common";

// project import
import ReactHlsPlayer from '../../components/ReactHlsPlayer';

StylesManager.applyTheme("modern");

export interface SubtitleConfig {
  fileName: string;
  language: string;
  label: string;
}

interface PlayerProps {
  title: string;
  description: string;
  url: string;
  patientId?: string;
  contentId?: string;
  posterFileName?: string;
  subtitleConfig?: SubtitleConfig;
}

export interface PlaybackEvent {
  id?: number;
  contentId: string;
  patientId: string;
  url: string;
  event: string;
  contentDuration: number;
  durationPlayed: number;
  timestamp: Date;
}

export async function savePlaybackEvent(playbackEvent: PlaybackEvent) {
  return http.post("/api/savePlaybackEvent/", JSON.stringify(playbackEvent));
}

const HLSPlayer = (props: PlayerProps) => {
  let vContainerRef = useRef<HTMLVideoElement | null>(null);

  function logPlaybackEvent(event: Event) {
    const currentTime = vContainerRef.current?.currentTime;
    savePlaybackEvent({
      patientId: props.patientId!,
      contentId: props.contentId!,
      url: props.url,
      durationPlayed: currentTime ? currentTime : 0,
      contentDuration: vContainerRef.current?.duration!,
      event: event.type,
      timestamp: new Date(),
    });
  }

  function listener(e: BeforeUnloadEvent) {
    const currentTime = vContainerRef.current?.currentTime;
    savePlaybackEvent({
      patientId: props.patientId!,
      contentId: props.contentId!,
      url: props.url,
      durationPlayed: currentTime ? currentTime : 0,
      contentDuration: vContainerRef.current?.duration ? vContainerRef.current?.duration : 0,
      event: "closed",
      timestamp: new Date(),
    });
  }

  useEffect(() => {
    vContainerRef.current!.addEventListener("play", logPlaybackEvent);
    vContainerRef.current!.addEventListener("pause", logPlaybackEvent);
    vContainerRef.current!.addEventListener("ended", logPlaybackEvent);
    vContainerRef.current!.addEventListener("loadeddata", logPlaybackEvent);
    vContainerRef.current!.addEventListener("stalled", logPlaybackEvent);
    vContainerRef.current!.addEventListener("suspend", logPlaybackEvent);
    vContainerRef.current!.addEventListener("waiting", logPlaybackEvent);
    vContainerRef.current!.addEventListener("abort", logPlaybackEvent);
    vContainerRef.current!.setAttribute("crossorigin", "anonymous");
    
    window.addEventListener("beforeunload", listener);

    return () => {
      vContainerRef.current!.removeEventListener("play", logPlaybackEvent);
      vContainerRef.current!.removeEventListener("pause", logPlaybackEvent);
      vContainerRef.current!.removeEventListener("ended", logPlaybackEvent);
      vContainerRef.current!.removeEventListener(
        "loadeddata",
        logPlaybackEvent
      );
      vContainerRef.current!.removeEventListener("stalled", logPlaybackEvent);
      vContainerRef.current!.removeEventListener("suspend", logPlaybackEvent);
      vContainerRef.current!.removeEventListener("waiting", logPlaybackEvent);
      vContainerRef.current!.addEventListener("abort", logPlaybackEvent);
      window.addEventListener("beforeunload", listener);
    };
  });

  return (
    <ReactHlsPlayer
      src={props.url}
      autoPlay={false}
      controls={true}
      width="100%"
      height="auto"
      playerRef={vContainerRef}
      // hlsConfig={{
      //   enableWebVTT: true,
      //   renderTextTracksNatively: true
      // }}
      poster={props.posterFileName ? `https://rcpublicstorage.blob.core.windows.net/web-files/VideoPosters/${props.posterFileName}` : undefined}
    >
      {props.subtitleConfig ? 
        <track default kind="captions" label={props.subtitleConfig.label} srcLang={props.subtitleConfig.language} src={`https://rcpublicstorage.blob.core.windows.net/web-files/Subtitles/${props.subtitleConfig.fileName}`}/> :
        <></>
      }
    </ReactHlsPlayer>
  );
};

export default HLSPlayer;
