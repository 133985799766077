import { Alert, AlertColor, Rating, Snackbar } from "@mui/material";
import { useState } from "react";
import http from "../../http-common";
import { ContentGuidType } from "../../models/ContentGuidType";

interface ContentRequestRatingProps {
  contentGuid: string;
  memoraPatientId?: null;
  contentGuidType: ContentGuidType.contentRequestKey
}

interface ContentConfigurationRatingProps {
  contentGuid: string;
  memoraPatientId: string;
  contentGuidType: ContentGuidType.contentConfigurationId
}

type ContentRatingProps = ContentRequestRatingProps | ContentConfigurationRatingProps

const ContentRating = ({contentGuid, memoraPatientId, contentGuidType}: ContentRatingProps) => {
  const [rating, setRating] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>();
  
  return (
    <>
      <Rating
        name="content-rating"
        value={rating}
        disabled={loading}
        onChange={async (event, newValue) => {
          setLoading(true);
          try {
            if (contentGuidType === ContentGuidType.contentConfigurationId) {
              await http.post("/api/saveContentRatingViaContentConfigurationId/", JSON.stringify({contentConfigurationId: contentGuid, memoraPatientId, rating: newValue}));
            } else {
              await http.post("/api/saveContentRatingViaContentRequestKey/", JSON.stringify({contentRequestKey: contentGuid, rating: newValue}));
            }
            
            setRating(newValue);
            setSnackbarMessage("Rating Saved!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
          } catch (error) {
            setSnackbarMessage("An Error Occured Saving Your Rating");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          }
          setLoading(false);
        }}
        size="large"
      />
      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        open={snackbarOpen}
        onClose={() => {setSnackbarOpen(false)}}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => {setSnackbarOpen(false)}}
          severity={snackbarSeverity}
          sx={{width:'100%'}}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
    
  );
}

export default ContentRating;