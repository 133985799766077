import { createTheme } from "@mui/material/styles";

const arcBlue = "#0B72B9";
const arcOrange = "#FFBA60";

export default createTheme({
  typography: {
    fontSize: 16,
    h3: {
      fontWeight: 500,
    },
  },
  // palette: {
  //     common: {
  //         blue: `${arcBlue}`,
  //         orange: `${arcOrange}`,
  //     },
  //     primary: {
  //         main: `${arcBlue}`,
  //     },
  //     secondary: {
  //         main: `${arcOrange}`,
  //     },
  // },
});
