import { EnrollmentVideoPlayer } from "../EnrollmentVideoPlayer";
import { BrandProvider } from './BrandContext';

// Sections
import EnrollmentHero from './sections/EnrollmentHero';
import EnrollmentIntro from './sections/EnrollmentIntro';
import EnrollmentVideo from './sections/EnrollmentVideo';
import EnrollmentReply from './sections/EnrollmentReply';
import EnrollmentRemi from './sections/EnrollmentRemi';
import EnrollmentTestimonials from './sections/EnrollmentTestimonials';
import EnrollmentConsent from './sections/EnrollmentConsent';
import EnrollmentFAQs from './sections/EnrollmentFAQs';

// Custom Components
import Header from './components/Header';
import Footer from './components/Footer';
import '../../../global.css';

export const SymptomManagementPreEnroll = () => {
  return (
    <EnrollmentVideoPlayer
      title="Symptom Managment Program Introduction"
      description="The Symptom Management Program is a proactive, on-demand service designed to make your cancer experience easier and more personal. Remi, our Virtual Assistant, can talk to you via text any time. You don't need to worry about downloading apps or remembering passwords."
      videoUrl="https://ep-rcmediaseprod-mk-io-video-streami-d3b7.eastus.streaming.mediakind.com/8cf6562d-6d56-4214-be77-7110a254b48b/RC_English_SymptomMgmt_PREENROLL.ism/manifest(format=m3u8-cmaf)"
      posterFileName="SymptomManagement9x16.png"
      subtitleConfig={{fileName: "RC_English_SymptomMgmt_PREENROLL_9x16-en-US.vtt", label: "English", language: "en"}}
    />
    // <BrandProvider>
    //   <Header />
    //   <EnrollmentHero />
    //   <EnrollmentIntro />
    //   {/* Step 1 - Watch Video */}
    //   <EnrollmentVideo  />
    //   {/* Step 2 - Reply Y */}
    //   <EnrollmentReply />
    //   {/* Step 3 - Remi */}
    //   <EnrollmentRemi />
    //   <EnrollmentTestimonials />
    //   <EnrollmentConsent />
    //   <EnrollmentFAQs />
    //   <Footer />
    // </BrandProvider>
  );
}

