import { useEffect, useState } from "react";
import http from "../../../http-common";
import { useParams } from "react-router-dom";

// project import
import CircularProgress from "@mui/material/CircularProgress";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { PatientAddress, PatientData } from "../../../types/patient";
import { ProviderLogo } from "./provider-logo";
import { EnrollmentEligibiltyLookupForm } from "./enrollment-eligibility-lookup-form";
import { EnrollmentAddressForm } from "./enrollment-address-form";
import { Product, SelfEnrollCustomData, SelfEnrollmentCustomDataResponse } from "../../../types/selfEnrollment";
import Markdown from "react-markdown";
import { ExceptionOutlined } from "@ant-design/icons";
import { executeRecaptcha } from "../../../eventHandlers/executeRepatcha";
import { useGetQueryParameterValue } from "../../../hooks/useGetQueryParameterValue";
import { getClientIpAddress } from "../../../eventHandlers/getClientIpAddress";
import HLSPlayer from "../../video/HLSPlayer";

async function sendPatientToApi(patientData: PatientData, token: string) {
  const ip = await getClientIpAddress();
  return http.post("/api/EnrollPatient/", JSON.stringify({...patientData, recaptchaToken: token, ipAddress: ip }));
}

async function getSelfEnrollMessaging(provider: string) {
  var content = await http.get<SelfEnrollmentCustomDataResponse>(`/api/GetSelfEnrollmentCustomData/${provider}`);
  return content;
}

const initialAddressForm: PatientAddress = {
  streetAddress1: "",
  streetAddress2: "",
  city: "",
  state: "",
  zip: "",
};

const Video = styled(Box)(({ theme }) => ({
    [theme.breakpoints.between("xs", "sm")]: {
      width: "75%"
    },
    [theme.breakpoints.between("sm", "md")]: {
      width: "60%"
    },
    [theme.breakpoints.between("md", "lg")]: {
      width: "50%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "20%"
    }
}));

const SelfEnroll = () => {
  const { provider } = useParams();
  const products = useGetQueryParameterValue("product")?.split(',');
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [pageError, setPageError] = useState("");
  const [selfEnrollCustomData, setSelfEnrollCustomData] = useState({
    displayName: "",
    welcomeMessage: "",
    communicationInformation: "",
    successMessage: "",
    errorMessage: "",
    useEligiblePatientAddress: false
  } as SelfEnrollCustomData)
  const [activeStep, setActiveStep] = useState(0);
  const [eligibleLookupFormData, setEligibleLookupFormData] = useState({
    enrollmentCode: "",
    firstName: "",
    lastName: "",
    contact: "",
    dob: null,
  });
  const [addressFormData, setAddressFormData] = useState(initialAddressForm);
  const [recaptchaSiteKey, setRecaptchaSiteKey] = useState('');
  const [enrollInMedManagement, setEnrollInMedManagment] = useState(products?.includes(Product.medManagement) ? true : false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const enrollPatient = async(patientDataToSave: PatientData, stepToSkipTo: number | undefined = undefined) => {
    setLoading(true);
    setErrorMessage("");
    
    const token = await executeRecaptcha(recaptchaSiteKey, 'submitSelfEnrollForm');
    
    await sendPatientToApi(patientDataToSave, token)
      .then((response) => {
        setLoading(false);

        switch(response.data.status.toLowerCase()) {
          case("error"):
            setErrorMessage(`${response.data.errorInformation}. ${selfEnrollCustomData.errorMessage}`);
            break;
          case("success"):
            if (stepToSkipTo === undefined) {
              handleNext();
            } else {
              setActiveStep(stepToSkipTo);
            }
            
            break;
          default:
            setErrorMessage(`${response.data.errorInformation}. ${selfEnrollCustomData.errorMessage}`);
            break;
        }
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(selfEnrollCustomData.errorMessage);
      });
  }

  useEffect(() => {
    async function fetchCustomData() {
      await getSelfEnrollMessaging(provider!).then((response) => {
        switch(response.data.status.toLowerCase()) {
          case('error'):
            setPageError(response.data.errorInformation);
            break;
          case('success'):
            setSelfEnrollCustomData(response.data.data);
            break;
          default:
            setPageError("We are sorry, something went wrong. Please ask your care team for further assistance.");
            break;
        }

        setPageLoading(false);
      })
      .catch(() => {
        setPageError("We are sorry, something went wrong. Please ask your care team for further assistance.");
        setPageLoading(false);
      })
    }
    async function setupRecaptcha() {
      await http.get<string>('/api/GetRecaptchaSiteKey/').then((response) => {
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${response.data}`
        document.body.appendChild(script)

        setRecaptchaSiteKey(response.data);
      });
    }

    setupRecaptcha();
    fetchCustomData();
    document.title = 'Symptom Management at Home Enrollment';

    const validProducts = Object.values(Product).map((productName, index) => {
      return productName.toString().toLowerCase();
    });

    products?.forEach((product) => {
      if (!validProducts.includes(product.toLowerCase())) {
        setPageError("We are sorry, something went wrong. Please ask your care team for further assistance.")
      }
    })

  }, []);

  if(pageError) {
    return (
      <Grid
        container
        spacing={10}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh", pt: 1.5, pb: 1, overflow: "hidden" }}
      >
        <Grid item xs={12}>
          <Stack direction="row">
            <Grid item>
              <Box sx={{}}>
                <>
                  <ExceptionOutlined style={{ fontSize: "2000%" }} rev={undefined} />
                </>
              </Box>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Paper
              sx={{
                p: 2,
                bgcolor: "#F7F4F3",
                gridTemplateColumns: { md: "1fr 1fr" },
                gap: 2,
              }}
            >
              <Typography align="center" variant="h6">
                Error
              </Typography>
              <Typography
                color="textSecondary"
                align="center"
                sx={{ width: { xs: "100%", sm: "100%" } }}
              >
                {pageError}
              </Typography>
            </Paper>
          </Stack>
        </Grid>
      </Grid>
    );
  } else if (pageLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <AppBar color="inherit">
        <Toolbar disableGutters={true}>
          <ProviderLogo providerName={provider} />
          <Typography>
            {selfEnrollCustomData.displayName}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key="welcome">
            <StepLabel>Welcome</StepLabel>
            <StepContent>
              <Typography>
                {selfEnrollCustomData.welcomeMessage}
              </Typography>
              {enrollInMedManagement ?
                (selfEnrollCustomData.medicationManagementWelcomeVideoUrl !== "" ? 
                  <Video>
                    <HLSPlayer
                      title="Welcome"
                      description="Welcome to Symptom Management"
                      url={selfEnrollCustomData.medicationManagementWelcomeVideoUrl}
                      subtitleConfig={selfEnrollCustomData.medicationManagementSubtitleFileName ? 
                        { fileName: selfEnrollCustomData.medicationManagementSubtitleFileName, language: selfEnrollCustomData.medicationManagementSubtitleLanguage, label: selfEnrollCustomData.medicationManagementSubtitleLabel } :
                        undefined
                      }
                    />
                  </Video> :
                  undefined) :
                  (selfEnrollCustomData.symptomManagementWelcomeVideoUrl !== "" ? 
                  <Video>
                    <HLSPlayer
                      title="Welcome"
                      description="Welcome to Symptom Management"
                      url={selfEnrollCustomData.symptomManagementWelcomeVideoUrl}
                      subtitleConfig={selfEnrollCustomData.symptomManagementSubtitleFileName ? 
                        { fileName: selfEnrollCustomData.symptomManagementSubtitleFileName, language: selfEnrollCustomData.symptomManagmentSubtitleLanguage, label: selfEnrollCustomData.symptomManagmentSubtitleLabel } :
                        undefined
                      }
                    />
                  </Video> :
                  undefined)
              }
                            
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Continue
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step key="Enrollment Form">
            <StepLabel>Enrollment Form</StepLabel>
            <StepContent TransitionProps={{ unmountOnExit: false }}>
              <EnrollmentEligibiltyLookupForm
                provider={provider}
                products={products}
                providerSpecificErrorMessage={selfEnrollCustomData.errorMessage}
                recaptchaSiteKey={recaptchaSiteKey}
                onBackButtonClick={handleBack}
                buttonLoading={loading}
                error={errorMessage}
                onSuccessfulSubmit={async (enrolledProducts, formValues, patientAlreadyEnrolled) => {
                  setEligibleLookupFormData(formValues);

                  if (patientAlreadyEnrolled && enrolledProducts !== undefined && enrolledProducts.includes(Product.medManagement)) {
                    setEnrollInMedManagment(false);                   
                  }
                  handleNext();
                }}
              />
            </StepContent>
          </Step>
          {!selfEnrollCustomData.useEligiblePatientAddress ? 
            <Step key="Confirm Address">
              <StepLabel>Confirm Address</StepLabel>
              <StepContent TransitionProps={{ unmountOnExit: false }}>
                <EnrollmentAddressForm
                  onBackButtonClick={handleBack}
                  onSubmit={(submittedValues) => {
                    setAddressFormData(submittedValues);
                    handleNext();
                  }}
                />
              </StepContent>
            </Step> :
            undefined        
          }
          <Step key="Communication Information">
            <StepLabel>Communication Information</StepLabel>
            <StepContent>
              <Markdown>{selfEnrollCustomData.communicationInformation}</Markdown>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    I understand
                  </Button>
                  <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step key="communication consent">
            <StepLabel>Communication Consent</StepLabel>
            <StepContent>
              <Markdown>{selfEnrollCustomData.communicationConsent}</Markdown>
              <Box sx={{ mb: 2 }}>
                <div>
                  {loading && (
                    <CircularProgress
                      size={30}
                      sx={{
                        position: "absolute",
                        marginTop: "12px",
                        marginLeft: "28px",
                      }}
                    />
                  )}
                  <Button
                    variant="contained"
                    disabled={loading}
                    onClick={async () => {
                      const patientDataToSave: PatientData = {
                        enrollmentCode: eligibleLookupFormData.enrollmentCode,
                        firstName: eligibleLookupFormData.firstName,
                        lastName: eligibleLookupFormData.lastName,
                        dob: eligibleLookupFormData.dob!,
                        provider: provider ?? '',
                        contact: `+1${eligibleLookupFormData.contact}`,
                        address: {
                          streetAddress1: addressFormData.streetAddress1,
                          streetAddress2: addressFormData.streetAddress2,
                          city: addressFormData.city,
                          state: addressFormData.state,
                          zip: addressFormData.zip
                        },
                        enrollInMedManagement: enrollInMedManagement
                      };
                      await enrollPatient(patientDataToSave);
                    }}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    I Agree
                  </Button>
                  <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    Back
                  </Button>
                </div>
                <Typography variant="caption"><Markdown>{selfEnrollCustomData.agreeDisclaimer}</Markdown></Typography>
                {errorMessage && (
                  <Alert severity="error" sx={{ mt: 1 }}>
                    {errorMessage}
                  </Alert>
                )}
              </Box>
            </StepContent>
          </Step>
          <Step key="Thank You">
            <StepLabel>Thank You</StepLabel>
            <StepContent>
              <Alert severity="success">
                {selfEnrollCustomData.successMessage}
              </Alert>
            </StepContent>
          </Step>
        </Stepper>
      </Box>
    </>
  );
};

export default SelfEnroll;
