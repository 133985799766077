import axios from "axios";

const getBaseUrl = () => {
  let url;
  switch (process.env.NODE_ENV) {
    case "production":
      url = process.env.REACT_API;
      break;
    case "development":
    default:
      url = "http://localhost:8080";
  }

  return url;
};

export default axios.create({
  baseURL: getBaseUrl(),
  headers: {
    "Content-type": "application/json",
  },
});
