import "survey-core/modern.min.css";
import { StylesManager } from "survey-core";
import { useEffect, useState } from "react";
import http from "../../http-common";
import { useParams } from "react-router-dom";

// project import
import { ApiResponse, TextContent } from "../../models/RCContent";
import ErrorMessage from "../maintenance/error";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Link, Stack, styled, useMediaQuery } from "@mui/material";
import Markdown from "react-markdown";
import Grid from "@mui/material/Unstable_Grid2/";
import React from "react";
import { getClientIpAddress } from "../../eventHandlers/getClientIpAddress";
import ContentRating from "./ContentRating";
import { ContentGuidType } from "../../models/ContentGuidType";

StylesManager.applyTheme("modern");

async function getContent(PatientId: string, ContentId: string) {
  const ip = await getClientIpAddress();
  var content = await http.get<ApiResponse>(
    `/api/retrieveStaticContent/${PatientId}/${ContentId}/${ip}`
  );
  return content;
}

export async function saveContentViewed(contentKey: string) {
  return http.post(
    "/api/saveData/" + contentKey,
    JSON.stringify({
      contentKey: contentKey,
      data: { comment: "Patient viewed content." },
    })
  );
}

const TitleItem = styled(Box)(({ theme }) => ({
  backgroundColor: '#443768',
  ...(useMediaQuery(theme.breakpoints.up('sm')) ? theme.typography.h2 : theme.typography.h6),
  padding: theme.spacing(1),
  textAlign: 'left',
  color: 'white'
}));

const CreditsItem = styled(Box)(({ theme }) => ({
  backgroundColor: '#443768',
  ...(useMediaQuery(theme.breakpoints.up('sm')) ? theme.typography.subtitle1 : theme.typography.subtitle2),
  padding: theme.spacing(1),
  textAlign: 'left',
  color: 'white'
}));

const SectionHeaderItem = styled(Box)(({ theme }) => ({
  backgroundColor: '#443768',
  ...(useMediaQuery(theme.breakpoints.up('sm')) ? theme.typography.h4 : theme.typography.h6),
  padding: theme.spacing(3),
  textAlign: 'left',
  color: 'white'
}));

const SectionBodyItem = styled(Box)(({ theme }) => ({
  ...(useMediaQuery(theme.breakpoints.up('sm')) ? theme.typography.body1 : theme.typography.body1),
  padding: (useMediaQuery(theme.breakpoints.up('sm')) ? theme.spacing(1, 10) : theme.spacing(1)),
  textAlign: 'left'
}));

const DisclaimerItem = styled(Box)(({ theme }) => ({
  ...(useMediaQuery(theme.breakpoints.up('sm')) ? theme.typography.body2 : theme.typography.body2),
  padding: theme.spacing(1),
  textAlign: 'left'
}));

const SourcesItem = styled(Box)(({ theme }) => ({
  ...(useMediaQuery(theme.breakpoints.up('sm')) ? theme.typography.body2 : theme.typography.body2),
  padding: (useMediaQuery(theme.breakpoints.up('sm')) ? theme.spacing(1, 10) : theme.spacing(1)),
  textAlign: 'left',
  color: 'grey'
}));

const CopyrightItem = styled(Box)(({ theme }) => ({
  ...(useMediaQuery(theme.breakpoints.up('sm')) ? theme.typography.body1 : theme.typography.body2),
  padding: theme.spacing(1),
  textAlign: 'left',
  color: 'grey'
}));

const RatingItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0,1)
}));

const RCContent = () => {
  const { memoraPatientId, contentId } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [educationalContent, setEducationalContent] = useState<TextContent>();
  const [contentKey, setContentKey] = useState('');
  let incrementedBottomCount = false;

  const handleScroll = async () => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

    if (bottom && !incrementedBottomCount) {
      incrementedBottomCount = true;
      await http.post("/api/incrementReachedBottomCount/" + contentKey);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [contentKey, incrementedBottomCount]);

  useEffect(() => {
    async function fetchContent(patientId: string, contentId: string) {
      await getContent(patientId, contentId)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "SUCCESS") {
              setEducationalContent(response.data.contentDefinintion as any as TextContent);
              setContentKey(response.data.contentKey)
              saveContentViewed(response.data.contentKey);
            } else {
              switch (response.data.status) {
                case "KEY-INVALID": {
                  setErrorMessage(
                    "There seems to be a problem with this link..."
                  );
                  setErrorStatus("Invalid link!");
                  break;
                }
                default: {
                  setErrorMessage(
                    "This link is expired and no longer usable..."
                  );
                  setErrorStatus("Expired link!");
                }
              }
            }
          }
          setLoading(false);
        })
        .catch((response) => {
          setErrorMessage("Error getting content!");
          setLoading(false);
        });
    }
    if (educationalContent === undefined && memoraPatientId && contentId) {
      fetchContent(memoraPatientId, contentId);
    }
  }, [educationalContent, contentId, memoraPatientId]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {errorMessage ? (
            <ErrorMessage
              errorMessage={errorMessage}
              errorStatus={errorStatus}
            />
          ) : (
            <Box sx={{ paddingLeft: {lg:'15%'}, paddingRight: {lg:'15%'} }} paddingBottom={'1%'}>
              <Stack spacing={1} sx={{ border: {lg: 1} }}>
                <Grid container spacing={1}>
                  <Grid
                    xs={12}
                  >
                    <TitleItem>{educationalContent?.title}</TitleItem>
                    <CreditsItem>{educationalContent?.credits}</CreditsItem>
                  </Grid>
                </Grid>
                <Box
                  component="img"
                  alignSelf={'center'}
                  sx={{
                    width:'100%',
                    maxWidth:'526px',
                    height:'auto',
                  }}
                  alt={educationalContent?.imageDescription}
                  src={`https://rcpublicstorage.blob.core.windows.net/web-files/EducationImages/${educationalContent?.imageFileName}`}
                />
                <Grid container>
                  <Grid xs={12}> {/* Markdown wraps text in a <p> for some reason, which throws stuff off sometimes. So I'm replacing the p with an empty React Fragment <> to avoid issues */}
                    <SectionHeaderItem><Markdown components={{p: React.Fragment}}>{educationalContent?.summaryHeader ?? ''}</Markdown></SectionHeaderItem>
                  </Grid>
                </Grid>
                <SectionBodyItem><Markdown>{educationalContent?.summaryBody ?? ''}</Markdown></SectionBodyItem>
                {educationalContent?.sections.map((section, index) => {
                  return (
                    <div key={index}>
                      <Grid container>
                        <Grid xs={9} md={7}>
                          <SectionHeaderItem><Markdown components={{p: React.Fragment}}>{section.header}</Markdown></SectionHeaderItem>
                        </Grid>
                      </Grid>
                      <SectionBodyItem><Markdown>{section.body}</Markdown></SectionBodyItem>
                    </div>
                  )
                })}
                <Grid container>
                  <Grid xs={9} md={7}>
                    <SectionHeaderItem><Markdown components={{p: React.Fragment}}>_Sources_</Markdown></SectionHeaderItem>
                  </Grid>
                </Grid>
                <SourcesItem><Markdown>{educationalContent?.sources ?? ''}</Markdown></SourcesItem>
                <Grid container>
                  <Grid xs={9} md={7}>
                    <SectionHeaderItem><Markdown components={{p: React.Fragment}}>_How would you rate this content?_</Markdown></SectionHeaderItem>
                  </Grid>
                </Grid>
                <RatingItem>
                  <ContentRating contentGuid={contentId!} contentGuidType={ContentGuidType.contentConfigurationId} memoraPatientId={memoraPatientId!}/>
                </RatingItem>
                <DisclaimerItem><Markdown>{educationalContent?.disclaimer ?? ''}</Markdown></DisclaimerItem>
                <CopyrightItem><Markdown>{educationalContent?.copyRight ?? ''}</Markdown> <Link href="/content/terms" target="_blank" rel="noopener noreferrer">Website Terms</Link></CopyrightItem>
              </Stack>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default RCContent;
