import "survey-core/modern.min.css";
import { Model, StylesManager } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import { useCallback, useEffect, useState } from "react";
import http from "../../http-common";
import { useParams } from "react-router-dom";
import { themeJson } from "../../themes/rc-theme";

// project import
import { ApiResponse } from "../../models/RCContent";
import ErrorMessage from "../maintenance/error";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import { getClientIpAddress } from "../../eventHandlers/getClientIpAddress";
import { getContent } from "../../eventHandlers/getContent";

//StylesManager.applyTheme(themeJson);

interface SurveyData {
  contentKey: string | undefined;
  data: string;
}

export async function sendSurveyToApi(surveyData: SurveyData) {
  return http.post(
    "/api/saveData/" + surveyData.contentKey,
    JSON.stringify(surveyData)
  );
}

async function getStaticContent(PatientId: string, ContentId: string) {
  const ip = await getClientIpAddress();
  var content = await http.get<ApiResponse>(
    `/api/retrieveStaticContent/${PatientId}/${ContentId}/${ip}`
  );
  return content;
}

const RCSurvey = () => {
  const { ContentKey, memoraPatientId, contentId } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [rcContent, setRcContent] = useState({
    status: "",
    errorInformation: "",
    contentId: "",
    contentKey: "",
    contentType: "",
    contentDefinintion: "",
  });

  useEffect(() => {
    async function fetchContent() {
      await getContent(ContentKey).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "SUCCESS") {
            setRcContent(response.data);
          } else {
            switch (response.data.status) {
              case "KEY-INVALID": {
                setErrorMessage(
                  "There seems to be a problem with this link..."
                );
                setErrorStatus("Invalid link!");
                break;
              }
              case "DATA-EXISTS": {
                setErrorMessage("This survey has already been completed.");
                setErrorStatus("Completed Survey!");
                break;
              }
              default: {
                setErrorMessage("This link is expired and no longer usable...");
                setErrorStatus("Expired link!");
              }
            }
          }
        } else {
          setErrorMessage("Error getting content!");
        }
        setLoading(false);
      });
    }

    async function fetchStaticContent(patientId: string, contentId: string) {
      await getStaticContent(patientId, contentId).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "SUCCESS") {
            setRcContent(response.data);
          } else {
            switch (response.data.status) {
              case "KEY-INVALID": {
                setErrorMessage(
                  "There seems to be a problem with this link..."
                );
                setErrorStatus("Invalid link!");
                break;
              }
              case "DATA-EXISTS": {
                setErrorMessage("This survey has already been completed.");
                setErrorStatus("Completed Survey!");
                break;
              }
              default: {
                setErrorMessage("This link is expired and no longer usable...");
                setErrorStatus("Expired link!");
              }
            }
          }
        } else {
          setErrorMessage("Error getting content!");
        }
        setLoading(false);
      });
    }

    if (ContentKey && rcContent.contentId === "") {
      fetchContent();
    }

    if (memoraPatientId && contentId && rcContent.contentId === "") {
      fetchStaticContent(memoraPatientId, contentId);
    }
  }, [memoraPatientId, contentId, ContentKey, rcContent.contentId]);

  const saveResults = useCallback(
    async (sender: any, options: any) => {
      const surveyData: SurveyData = {
        contentKey: rcContent.contentKey,
        data: sender.data,
      };
      options.showDataSaving();
      await sendSurveyToApi(surveyData).then((response) => {
        if (response.status === 200) {
          options.showDataSavingSuccess();
        } else {
          options.showDataSavingError();
        }
      });
    },
    [rcContent]
  );

  //   survey.onCompleting.add(function () {
  //     setLoading(true);
  //   });
  const survey = new Model(rcContent.contentDefinintion);
  survey.onComplete.add(saveResults);
  var showdown = require("showdown");
  var converter = new showdown.Converter();

  survey.applyTheme(themeJson);
  StylesManager.applyTheme("layered");

  survey.onTextMarkdown.add(function (survey, options) {
    // Convert Markdown to HTML
    let str = converter.makeHtml(options.text);
    // Remove root paragraphs <p></p>
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    // Set HTML markup to render
    options.html = str;
  });

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {errorMessage ? (
            <ErrorMessage
              errorMessage={errorMessage}
              errorStatus={errorStatus}
            />
          ) : (
            <Box>
              <Survey model={survey} />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default RCSurvey;
