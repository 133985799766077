import { Box } from '@mui/material';
import reimagineCareJpg from "../../images/logos/rc-logo-icon.jpg";
import { ReactComponent as RcLogo } from "../../../images/logos/rc-logo.svg";
import { ReactComponent as CCBD } from "../../../images/logos/ccbd-logo.svg";
import { ReactComponent as MHO } from "../../../images/logos/mho-logo-dark.svg";

export interface IProviderLogoProps {
  providerName: string | undefined;
}

export const ProviderLogo = ({
  providerName
}: IProviderLogoProps) => {

  const jpg = (source: string) => {
    return (
      <Box
        component="img"
        sx={{
          height: 40,
          width: 40,
          paddingLeft: 1,
          paddingRight: 2
        }}
        alt="logo"
        src={source}
      />
    );
  }

  const svg = (svgComponent: any) => {
    return (
      <Box sx={{
        paddingLeft: 1,
        paddingRight: 2
      }}>
        {svgComponent}
      </Box>
    )
  }
  
  switch (providerName?.toLowerCase()) {
    case 'mh':
      return svg(<RcLogo/>);
    case 'ccbd':
      return svg(<CCBD height={70} width={200}/>);
    case 'mho':
      return svg(<MHO/>)
    default:
      return svg(<RcLogo/>)
  }
}