import { Box, CircularProgress, Grid, Link, Stack, styled, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import theme from "../../theme";
import React from "react";
import ContentRating from "../content/ContentRating";
import { useParams } from "react-router-dom";
import { ContentGuidType } from "../../models/ContentGuidType";
import Error from "../maintenance/error";
import { getContent } from "../../eventHandlers/getContent";
import { ApiResponse, AudioContent } from "../../models/RCContent";
import http from "../../http-common";
import { getClientIpAddress } from "../../eventHandlers/getClientIpAddress";

const SectionHeaderItem = styled(Box)(({ theme }) => ({
  backgroundColor: '#443768',
  ...(useMediaQuery(theme.breakpoints.up('sm')) ? theme.typography.h4 : theme.typography.h6),
  padding: theme.spacing(3),
  textAlign: 'left',
  color: 'white'
}));

const SynopsisItem = styled(Box)(({ theme }) => ({
  backgroundColor: '#443768',
  ...(useMediaQuery(theme.breakpoints.up('sm')) ? theme.typography.subtitle1 : theme.typography.subtitle2),
  padding: theme.spacing(1),
  textAlign: 'left',
  color: 'white'
}));

const DisclaimerItem = styled(Box)(({ theme }) => ({
  ...(useMediaQuery(theme.breakpoints.up('sm')) ? theme.typography.body2 : theme.typography.body2),
  padding: theme.spacing(1),
  textAlign: 'left'
}));

const RatingItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0,1)
}));

const CopyrightItem = styled(Box)(({ theme }) => ({
  ...(useMediaQuery(theme.breakpoints.up('sm')) ? theme.typography.body1 : theme.typography.body2),
  padding: theme.spacing(1),
  textAlign: 'left',
  color: 'grey'
}));

async function getStaticContent(PatientId: string, ContentId: string) {
  const ip = await getClientIpAddress();
  var content = await http.get<ApiResponse>(
    `/api/retrieveStaticContent/${PatientId}/${ContentId}/${ip}`
  );
  return content;
}

export async function saveContentViewed(contentKey: string) {
  return http.post(
    "/api/saveData/" + contentKey,
    JSON.stringify({
      contentKey: contentKey,
      data: { comment: "Patient viewed audio." },
    })
  );
}

const RcAudio = () => {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [audioContent, setAudioContent] = useState<AudioContent>()
  const { contentId, memoraPatientId, contentKey } = useParams();
  let iFrameHeight = useMediaQuery(theme.breakpoints.down('md')) ? "100vh" : "calc(100vh - 50px)";


  useEffect(() => {
    async function fetchContent() {
      await getContent(contentKey).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "SUCCESS") {
            const audioContent: AudioContent = response.data.contentDefinintion as any as AudioContent;
            window.location.href = audioContent?.url;
            saveContentViewed(response.data.contentKey);
            //setAudioContent();
          } else {
            switch (response.data.status) {
              case "KEY-INVALID": {
                setErrorMessage(
                  "There seems to be a problem with this link..."
                );
                setErrorStatus("Invalid link!");
                break;
              }
              default: {
                setErrorMessage("This link is expired and no longer usable...");
                setErrorStatus("Expired link!");
              }
            }
          }
        } else {
          setErrorMessage("Error getting content!");
        }
      }).catch(() => {
        setErrorMessage("Error getting content!");
      });
      //setLoading(false);
    }

    async function fetchStaticContent(patientId: string, contentId: string) {
      await getStaticContent(patientId, contentId).then((response) =>{
        if (response.status === 200) {
          if (response.data.status === "SUCCESS") {
            const audioContent: AudioContent = response.data.contentDefinintion as any as AudioContent;
            window.location.href = audioContent?.url;

            saveContentViewed(response.data.contentKey);
          } else {
            switch (response.data.status) {
              case "KEY-INVALID": {
                setErrorMessage(
                  "There seems to be a problem with this link..."
                );
                setErrorStatus("Invalid link!");
                break;
              }
              case "INVALID-PATIENTID": {
                setErrorMessage("Invalid Patient");
                setErrorStatus("Invalid link!");
                break;
              }
              default: {
                setErrorMessage("This link is expired and no longer usable...");
                setErrorStatus("Expired link!");
              }
            }
          }
        } else {
          setErrorMessage("Error getting content!");
        }
        //setLoading(false);
      });
    }

    if (memoraPatientId && contentId) {
      fetchStaticContent(memoraPatientId, contentId);
    } else if (contentKey) {
      fetchContent();
    }
  }, [contentKey])

  return (
    <>
      {loading && !errorMessage ? (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {errorMessage ? (
            <Error
              errorMessage={errorMessage}
              errorStatus={errorStatus}
            />
          ) : (
            <Box sx={{ paddingLeft: {lg:'15%'}, paddingRight: {lg:'15%'} }} paddingBottom={'1%'}>
              <Stack spacing={1} sx={{ border: {lg: 1} }}>
                {audioContent?.synopsis !== '' ?
                  <SynopsisItem>{audioContent?.synopsis}</SynopsisItem> :
                  undefined
                }
                <Box
                  border={'none'}
                  height={iFrameHeight}
                  width={"100%"}
                  component="iframe"
                  src={audioContent?.url}
                />
                <Grid container>
                  <Grid item xs={9} md={7}>
                    <SectionHeaderItem><Markdown components={{p: React.Fragment}}>_How would you rate this content?_</Markdown></SectionHeaderItem>
                  </Grid>
                </Grid>
                <RatingItem>
                  <ContentRating contentGuid={contentKey!} contentGuidType={ContentGuidType.contentRequestKey}/>
                </RatingItem>
                <DisclaimerItem><Markdown>{audioContent?.disclaimer ?? ''}</Markdown></DisclaimerItem>
                <CopyrightItem><Markdown>{audioContent?.copyRight ?? ''}</Markdown> <Link href="/content/terms" target="_blank" rel="noopener noreferrer">Website Terms</Link></CopyrightItem>
              </Stack>
            </Box>
          )}
        </>
      )}
    </>
    
  )
}

export default RcAudio;