import { RcRoutes } from "./RcRoutes";

export default function App() {  
  return (
    <div
      style={{
        position: "absolute",
        //top: isMobile ? "10%" : "0",
        left: "0%",
        right: "0%",
        overflow: "inherit",
        userSelect: "none",
      }}
    >
      <div>
        <RcRoutes/>
      </div>
    </div>
  );
}
