export interface SelfEnrollmentCustomDataResponse {
   status: string;
   errorInformation: string;
   data: SelfEnrollCustomData;
}

export interface SelfEnrollMessaging {
  displayName: string;
  welcomeMessage: string;
  communicationInformation: string;
  successMessage: string;
  communicationConsent: string;
  agreeDisclaimer: string;
  errorMessage: string;
}

export interface SelfEnrollCustomData extends SelfEnrollMessaging {
  useEligiblePatientAddress: boolean;
  symptomManagementWelcomeVideoUrl: string;
  medicationManagementWelcomeVideoUrl: string;
  medicationManagementSubtitleFileName: string;
  medicationManagementSubtitleLanguage: string;
  medicationManagementSubtitleLabel: string;
  symptomManagementSubtitleFileName: string;
  symptomManagmentSubtitleLanguage: string;
  symptomManagmentSubtitleLabel: string;
}

export enum Product {
  medManagement = 'MedicationManagement'
}