import { Link, Outlet, Route, Routes } from "react-router-dom";
import RCContent from "./pages/content/content";
import SelfEnroll from "./pages/enroll/selfEnroll/self-enroll";
import RCSurvey from "./pages/survey/survey";
import WebsiteTerms from "./pages/content/WebsiteTerms";
import RcAudio from "./pages/audio/RcAudio";
import RcVideo from "./pages/video/RcVideo";
import { SymptomManagementPreEnroll } from "./pages/enroll/preEnroll/SymptomManagementPreEnroll";
import { SymptomManagementPostEnroll } from "./pages/enroll/postEnroll/SymptomManagementPostEnroll";

export const RcRoutes = () => {
  const subdomain = window.location.hostname.split(".")[0];

  return (
    // Routes nest inside one another. Nested route paths build upon
    // parent route paths, and nested route elements render inside
    // parent route elements. See the note about <Outlet> below.
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<SymptomManagementPreEnroll />} />
          {subdomain.includes("localhost") ? (
            <>
              <Route
                path="survey/:ContentKey"
                element={
                  <div>
                    <RCSurvey />
                  </div>
                }
              />
              <Route
                path="survey/static/:memoraPatientId/:contentId"
                element={
                  <div>
                    <RCSurvey />
                  </div>
                }
              />
              <Route
                path="video/static/:memoraPatientId/:contentId"
                element={
                  <div>
                    <RcVideo />
                  </div>
                }
              />
              <Route
                path="video/:contentKey"
                element={
                  <div>
                    <RcVideo />
                  </div>
                }
              />
              <Route
                path="audio/:contentKey"
                element={
                  <div>
                    <RcAudio />
                  </div>
                }
              />
              <Route
                path="audio/static/:memoraPatientId/:contentId"
                element={
                  <div>
                    <RcAudio />
                  </div>
                }
              />
              <Route
                path="content/static/:memoraPatientId/:contentId"
                element={
                  <div>
                    <RCContent />
                  </div>
                }
              />
              <Route path="content/terms" element={<WebsiteTerms />} />
              <Route
                path=":provider/"
                element={
                  <div>
                    <SelfEnroll />
                  </div>
                }
              />
              <Route
                path="welcome"
                element={
                  <SymptomManagementPostEnroll />
                }
              >
                <Route 
                  path=":memoraPatientId"
                  element={<SymptomManagementPostEnroll />}
                />
              </Route>
            </>
          ) : subdomain.includes("enroll") ? (
            <>
              <Route
                path=":provider/"
                element={
                  <div>
                    <SelfEnroll />
                  </div>
                }
              />
              <Route
                path="welcome"
                element={
                  <SymptomManagementPostEnroll />
                }
              >
                <Route 
                  path=":memoraPatientId"
                  element={<RcVideo contentId="9ffef2c9f16111ee8be046033b21f466" />}
                />
              </Route>
            </>
          ) : (
            <>
              <Route
                path="survey/:ContentKey"
                element={
                  <div>
                    <RCSurvey />
                  </div>
                }
              />
              <Route
                path="survey/static/:memoraPatientId/:contentId"
                element={
                  <div>
                    <RCSurvey />
                  </div>
                }
              />
              <Route
                path="video/static/:memoraPatientId/:contentId"
                element={
                  <div>
                    <RcVideo />
                  </div>
                }
              />
              <Route
                path="video/:contentKey"
                element={
                  <div>
                    <RcVideo />
                  </div>
                }
              />
              <Route
                path="audio/:contentKey"
                element={
                  <RcAudio />
                }
              />
              <Route
                path="audio/static/:memoraPatientId/:contentId"
                element={
                  <div>
                    <RcAudio />
                  </div>
                }
              />
              <Route
                path="content/static/:memoraPatientId/:contentId"
                element={
                  <div>
                    <RCContent />
                  </div>
                }
              />
              <Route path="content/terms" element={<WebsiteTerms />} />
            </>
          )}

          {/* Using path="*"" means "match anything", so this route
            acts like a catch-all for URLs that we don't have explicit
            routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
};

function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
