import { useQuery } from "./useQuery";

export function useGetQueryParameterValue(queryParameterName: string, caseSensitive: boolean = false) {
  const queryParams = useQuery();

  if (caseSensitive) {
    return queryParams.get(queryParameterName);
  } else {
    const caseInsensitiveParams = new URLSearchParams();
    
    // this was pulled straight from the official docs. not sure why typescript has an issue with it, so we'll just ignore it
    // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
    //@ts-ignore
    for (const [name, value] of queryParams.entries()) {
      caseInsensitiveParams.append(name.toLowerCase(), value);
    }

    return caseInsensitiveParams.get(queryParameterName.toLowerCase());
  }
}