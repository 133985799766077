import { ApiResponse } from "../models/RCContent";
import { getClientIpAddress } from "./getClientIpAddress";
import http from "../http-common";

export async function getContent(patientContentId: string | undefined) {
  const ip = await getClientIpAddress();
  var content = await http.get<ApiResponse>(
    `/api/retrieveContent/${patientContentId}/${ip}`
  );
  return content;
}