export async function executeRecaptcha(recaptchaSiteKey: string, actionName: string): Promise<string> {
  let repatchaToken = '';
  // @ts-ignore
  await window.grecaptcha
    .execute(recaptchaSiteKey, {action: actionName})
    .then((token: any) => {
      repatchaToken = token;
    });

  return repatchaToken;
}