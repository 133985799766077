import { Box, Divider, styled, Typography } from "@mui/material";
import HLSPlayer, { SubtitleConfig } from "../video/HLSPlayer";
import { Stack } from "@mui/system";

const Video = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between("xs", "sm")]: {
    width: "100%"
  },
  [theme.breakpoints.between("sm", "md")]: {
    width: "60%"
  },
  [theme.breakpoints.between("md", "lg")]: {
    width: "50%"
  },
  [theme.breakpoints.up("lg")]: {
    width: "20%"
  }
}));
const Title = styled(Typography)(({theme}) => ({
  [theme.breakpoints.between("xs", "sm")]: {
    ...theme.typography.h6
  },
  [theme.breakpoints.between("sm", "md")]: {
    ...theme.typography.h6
  },
  [theme.breakpoints.between("md", "lg")]: {
    ...theme.typography.h3
  },
  [theme.breakpoints.up("lg")]: {
    ...theme.typography.h3
  },
  backgroundColor: '#443768',
  color: 'white',
  paddingLeft: theme.spacing(1),
  textAlign: 'center'
}));

const Description = styled(Typography)(({theme}) => ({
  ...theme.typography.caption,
  backgroundColor: '#443768',
  color: 'white',
  paddingLeft: theme.spacing(1),
  textAlign: 'center'
}));

interface EnrollmentVideoPlayerProps {
  title: string;
  description: string;
  videoUrl: string;
  posterFileName: string;
  subtitleConfig: SubtitleConfig
}

export const EnrollmentVideoPlayer = ({title, description, videoUrl, posterFileName, subtitleConfig}: EnrollmentVideoPlayerProps) => {
  return (
    <Stack>
      <Title>{title}</Title>
      <Divider/>
      <Description>{description}</Description>
      <Video>
        <HLSPlayer
          title={title}
          description={description}
          url={videoUrl}
          posterFileName={posterFileName}
          subtitleConfig={subtitleConfig}
        />
      </Video>
    </Stack>
  );
}

