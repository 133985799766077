// material-ui
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";

// assets
import surveyIcon from "../../images/survey-icon-19203.png";
import checkMark from "../../images/success-green-check-mark-icon.svg";

// ==============================|| ERROR 404 - MAIN ||============================== //

interface errorProps {
  errorMessage: string;
  errorStatus: string;
}

function Error(props: errorProps) {
  return (
    <>
      <Grid
        container
        spacing={10}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh", pt: 1.5, pb: 1, overflow: "hidden" }}
      >
        <Grid item xs={12}>
          <Stack direction="row">
            <Grid item>
              <Box sx={{}}>
                {props.errorStatus === "Completed Survey!" ? (
                  <img
                    src={checkMark}
                    alt="Completed"
                    style={{ width: "100%", height: "100%" }}
                  />
                ) : (
                  <>
                    <img
                      src={surveyIcon}
                      alt="Error"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </>
                )}
              </Box>
            </Grid>
            <Grid item sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  top: 60,
                  left: -40,
                  width: { xs: 130, sm: 390 },
                  height: { xs: 115, sm: 330 },
                }}
              >
                {/* <img
                  src={errorSvg}
                  alt="mantis"
                  style={{ width: "100%", height: "100%" }}
                /> */}
              </Box>
            </Grid>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Paper
              sx={{
                p: 2,
                bgcolor: "#F7F4F3",
                gridTemplateColumns: { md: "1fr 1fr" },
                gap: 2,
              }}
            >
              <Typography align="center" variant="h6">
                {props.errorStatus}
              </Typography>
              <Typography
                color="textSecondary"
                align="center"
                sx={{ width: { xs: "100%", sm: "100%" } }}
              >
                {props.errorMessage}
              </Typography>
              <br />
              <Typography
                color="textSecondary"
                align="center"
                sx={{ width: { xs: "100%", sm: "100%" } }}
              >
                Don't forget to text SYMPTOMS at any time if you have new or
                worsening symptoms.
              </Typography>
            </Paper>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default Error;
