import { Formik } from "formik";
import * as Yup from "yup";
import { PatientAddress } from "../../../types/patient";
import { Grid, TextField, MenuItem, Box, Button } from "@mui/material";
import { USStates } from "../../../models/USStates";
import { useState } from "react";

export interface IEnrollmentAddressForm {
  onSubmit(submittedValues: any): void;
  onBackButtonClick(): void;
}

const initialFormValues: PatientAddress = {
  streetAddress1: "",
  streetAddress2: "",
  city: "",
  state: "",
  zip: "",
};

export const EnrollmentAddressForm = ({
  onSubmit,
  onBackButtonClick
}: IEnrollmentAddressForm) => {
  const zipRegEx = /^\d{5}(?:[-\s]\d{4})?$/;
  const [formData, setFormData] = useState(initialFormValues);
  
  return (
    <Formik
      initialValues={formData}
      validationSchema={Yup.object().shape({
        streetAddress1: Yup.string()
          .required("Street Address is required.")
          .nullable(),
        city: Yup.string().required("City is required.").nullable(),
        state: Yup.string().required("State is required.").nullable(),
        zip: Yup.string()
          .required("Zip Code is required")
          .matches(zipRegEx, "Invalid Zip Code"),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ) => {
        setFormData(values);
        onSubmit(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        values,
      }) => (
        <form
          id="confirm-address-form"
          noValidate
          onSubmit={handleSubmit}
        >
          <Grid
            sx={{
              display: "grid",
              gridTemplateColumns:
                "repeat(auto-fill, minmax(250px, 1fr))",
            }}
            container
            spacing={4}
            paddingBottom={2}
          >
            <Grid item>
              <TextField
                placeholder="Street Address"
                fullWidth
                required
                id="street-address1"
                name="streetAddress1"
                value={values.streetAddress1}
                label={"Street Address"}
                variant="standard"
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.streetAddress1 &&
                  Boolean(errors.streetAddress1)
                }
                helperText={
                  touched.streetAddress1 && errors.streetAddress1
                }
              />
            </Grid>
            <Grid item>
              <TextField
                placeholder="Street Address 2"
                fullWidth
                id="street-address2"
                name="streetAddress2"
                value={values.streetAddress2}
                label={"Street Address 2"}
                variant="standard"
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.streetAddress2 &&
                  Boolean(errors.streetAddress2)
                }
                helperText={
                  touched.streetAddress2 && errors.streetAddress2
                }
              />
            </Grid>
            <Grid item>
              <TextField
                placeholder="State"
                fullWidth
                required
                select
                id={"state"}
                name={"state"}
                value={values.state}
                label={"State"}
                variant="standard"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.state && Boolean(errors.state)}
                helperText={touched.state && errors.state}
              >
                {USStates.map((i) => {
                  return (
                    <MenuItem key={i.abbreviation} value={i.abbreviation}>
                      {i.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item>
              <TextField
                placeholder="City"
                fullWidth
                required
                id={"city"}
                name={"city"}
                value={values.city}
                label={"City"}
                multiline={true}
                variant="standard"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.city && Boolean(errors.city)}
                helperText={touched.city && errors.city}
              />
            </Grid>
            <Grid item>
              <TextField
                placeholder="Zip Code"
                fullWidth
                required
                id={"zip"}
                name={"zip"}
                value={values.zip}
                label={"Zip Code"}
                variant="standard"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.zip && Boolean(errors.zip)}
                helperText={touched.zip && errors.zip}
              />
            </Grid>
          </Grid>
          <Box sx={{ mb: 2 }}>
            <div>
              <Button
                form="confirm-address-form"
                variant="contained"
                sx={{ mt: 1, mr: 1 }}
                type="submit"
              >
                Confirm
              </Button>
              <Button onClick={onBackButtonClick} sx={{ mt: 1, mr: 1 }}>
                Back
              </Button>
            </div>
          </Box>
        </form>
      )}
    </Formik>
  );
}