import { useParams } from "react-router-dom";
import { EnrollmentVideoPlayer } from "../EnrollmentVideoPlayer";

export const SymptomManagementPostEnroll = () => {
  const { memoraPatientId } = useParams();

  return (
    <EnrollmentVideoPlayer
      title="Symptom Managment Program Welcome Video"
      description="Please watch the Welcome Video below to learn more about the Symptom Management Program."
      videoUrl="https://ep-rcmediaseprod-mk-io-video-streami-d3b7.eastus.streaming.mediakind.com/f2dc9113-c782-49a8-94b5-2cadd459a2be/RC_English_SymptomMgmt_9x16.ism/manifest(format=m3u8-cmaf)"
      posterFileName="SymptomManagement9x16.png"
      subtitleConfig={{fileName: "RC_English_SymptomMgmt_9x16-en-US.vtt", label: "English", language: "en"}}
    />
  );
}

